import { Canvas } from "@react-three/fiber";
import Header from "../../components/header";
import EarthAnimal from "./model";
import "./styles.scss";
import { useNavigate } from "react-router";

const AboutMe = (): JSX.Element => {
  const navigate = useNavigate();

  const navigateToContact = (): void => {
    navigate("/contact");
  };

  return (
    <div className="about_me_container">
      <Header
        background="linear-gradient(59.82deg, rgb(138, 158, 79) 0%, rgb(80 112 75 / 0%) 49.36%, rgb(87 117 80 / 0%) 50.11%, rgb(95, 126, 95) 100%)"
        mobile_menu_bg="linear-gradient(59.82deg, rgb(138, 158, 79) 0%, rgb(80 112 75) 49.36%, rgb(87 117 80) 50.11%, rgb(95, 126, 95) 100%)"
      />
      <Canvas
        style={{
          height: "94%",
          width: "100%",
          position: "fixed",
          top: 55,
          right: 0,
        }}
      >
        <ambientLight intensity={20} />
        <EarthAnimal />
      </Canvas>
      <div className="text_container">
        <div className="text_content">
          <div>
            <div className="text_hi">HI THERE!! I'm</div>
            <div className="text_name">NAREK AVDALYAN</div>
            <div className="text_developer">A Full Stack Developer</div>
            <div className="text_description">
              As a Middle full stack developer with over three years of
              experience, I specialize in the design and development of
              cutting-edge applications. My career is characterized by a deep
              commitment to innovation and excellence, consistently pushing the
              boundaries of technology to deliver exceptional results. I am
              passionate about leveraging my technical expertise and creative
              problem-solving skills to contribute to projects that are not only
              challenging but also rewarding. Looking forward, I am eager to
              take on roles that will utilize my strengths in engineering,
              helping to drive success for forward-thinking organizations. Let's
              create something great together.
            </div>
          </div>
          <img src="images/avatar.png" alt="" className="avatar" />
        </div>
        <div className="buttons">
          <a
            className="resume"
            href="https://drive.google.com/file/d/1EShPie8dMk_2Wt_3tZIMkCmBvbNUUk8n/view?usp=drive_link"
            target="_blank"
            rel="noreferrer"
          >
            Resume{" "}
            <img src="icons/document.svg" alt="" width={20} height={20} />
          </a>
          <div className="contact" onClick={navigateToContact}>
            Contact With Me{" "}
            <img src="icons/email.svg" alt="" width={20} height={20} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutMe;
